@import "https://fonts.googleapis.com/css?family=Open+Sans:600,700";

* {
  font-family: "roboto", sans-serif;
}

.dashboard {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 300px 1fr 1fr;
  grid-template-rows: 100px 1fr 0px;
  grid-template-areas: 
    "header header header"
    "menu main main";
  /* background: #101b27; */
  background: #101b27;
  box-shadow: 100px rgba(255, 255, 255, 0.76);
  ;
  transition: all .3s;
  caret-color: transparent;
  color: #ffffff;
}

.header {
  grid-area: header;
  box-shadow:  rgba(255, 255, 255, 0.938);
  border-bottom: 1px solid #616b75;
}

.main {
  grid-area: main;
  background-color: rgb(0, 14, 23);
  /* background: radial-gradient(circle, rgb(30, 30, 30) 28%, rgba(0,0,0,1) 52%); */
  height: 100vh;
}

.footer {
  grid-area: footer;
  background: #ececec;
}

/* INICIO ESTILO DEL MENU */

.menu {
  grid-area: menu;
  background: #101b27;
    transition: all .3s;
  left: -250px;
  z-index: 1000;
}

.menu.active {
  left: 0px!important;
}

.menu.active >.ul-nav {
  width: 220px!important;
}

.menu >.ul-nav {
  height: 100%;
}

.ul-nav {
  width: 200px;
  padding-bottom: 60px;
  background-color: #101b27;
;
  z-index: 99;
  overflow-y: auto;
  will-change: transform;
  backface-visibility: hidden;
  padding: 0;
  transition: all .5s;
}

.ul-nav-li {
  display: block;
  user-select: none;
  list-style: none;
  font-size: .9rem;
}

.li-nav-div {
  padding: 0 0rem;
  line-height: inherit;
  cursor: pointer;
  transition: all .3s;
  height: 36px;
}

.li-nav-div i {
  width: 1rem;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  margin-right: .3rem;
}

.li-nav-div{
  background: #101b27;
  padding: 0 5px!important;
  /* border-bottom-right-radius: 20px; */
  /* border-top-right-radius: 20px; */
  height: 45px;
  margin-top: 10px;
  color: #ffffff;
}

.li-nav-div-submenu  {
  background: #101b27;
  padding: 0 8px!important;
  padding: 0 0rem;
  line-height: inherit;
  cursor: pointer;
  transition: all .3s;
  height: 40px;
}

.ul-submenu {
  padding: 0;
}

.ul-nav-li-submenu {
  display: block;
  list-style: none;
  font-size: .9rem;
}

.li-nav-div-submenu i {
  width: 1rem;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  margin-right: .3rem;
}

.fondo-negro {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgb(0, 0, 0);
  opacity: 0.3;

}
/* .active-li {
  background-color: #123253;
} */

/* .ul-submenu {
  border: 0;
  background-color: #123253;
  display: block;
} */

/* .active-submenu {
  background: #DBDBDB;
} */

.nav-link {
  padding: 0 0 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

/* Menu Activo */
.nav-link.active {
  color: #ffffff;

}

/* SubMenu */
.li-a-submenu.active {
  border: 1px solid #fff;
  padding: 10px;
  box-shadow: 0 0 2px #fff, 0 0 2px #fff inset;
  color: rgb(241, 145, 145);
  border-radius: 3px;

}

.nav-link:hover {
  color: #dfbcbc;
  box-shadow: 0 0 2px #fff, 0 0 2px #fff inset;
  color: #fff;
  border-radius: 3px;
}

.nav-link.active:hover {
  color: #c5c5c5;
}

.text-menu {
  font-size: 20px;
}

.text-sub-menu {
  padding-left: 10px;

}
/* FIN ESTILO DEL MENU */




/* ESTILOS INPUT */
.caret-input{
  cursor:caret;
  caret-color:rgb(0, 0, 0);
}
/* FIN ESTILOS INPUT */

/* ESTILO DEL SCROLLBAR  */
.containerScroll::-webkit-scrollbar {
  width: 8px;     /* Tamaño del scroll en vertical */
  height: 8px;    /* Tamaño del scroll en horizontal */
  /* display: none;  Ocultar scroll */
}

.containerScroll::-webkit-scrollbar-thumb {
  background: #f6682f;
  /* border-radius: 4px; */
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.containerScroll::-webkit-scrollbar-thumb:hover {
    background: #ce4008;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.containerScroll::-webkit-scrollbar-thumb:active {
    background-color: #d34f1b;
}
.containerScroll::-webkit-scrollbar-track {
    background: #6990ad;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo cuando esté en active o hover */
.containerScroll::-webkit-scrollbar-track:hover,
.containerScroll::-webkit-scrollbar-track:active {
    background: #6990ad;
}
 /* PARA DISPOSITIVOS MOBILES */
@media screen and (max-width: 639px) { 
    .containerScroll::-webkit-scrollbar {
        width: 1px;     /* Tamaño del scroll en vertical */
        height: 0px;    /* Tamaño del scroll en horizontal */
        /* display: none;  Ocultar scroll */
    }
    
    .containerScroll::-webkit-scrollbar-thumb {
        background: #ac5e5300;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    .containerScroll::-webkit-scrollbar-thumb:hover {
        /* background: #ac5e53; */
        /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2); */
    }
    
    /* Cambiamos el fondo cuando esté en active */
    .containerScroll::-webkit-scrollbar-thumb:active {
        /* background-color: #3e639b; */
    }
    .containerScroll::-webkit-scrollbar-track {
        background: #3e639b00;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo cuando esté en active o hover */
    .containerScroll::-webkit-scrollbar-track:hover,
    .containerScroll::-webkit-scrollbar-track:active {
        background: #3e639b00;
    }

}

/* ESTILO DEL SCROLLBARPRINCIPAL  */
.containerScrollPrincipal::-webkit-scrollbar {
    width: 10px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    /* display: none;  Ocultar scroll */
}

.containerScrollPrincipal::-webkit-scrollbar-thumb {
    background: #a13f18;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.containerScrollPrincipal::-webkit-scrollbar-thumb:hover {
    background: #a13f18;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.containerScrollPrincipal::-webkit-scrollbar-thumb:active {
    background-color: #a13f18;
}
.containerScrollPrincipal::-webkit-scrollbar-track {
    background: #f6682f;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo cuando esté en active o hover */
.containerScrollPrincipal::-webkit-scrollbar-track:hover,
.containerScrollPrincipal::-webkit-scrollbar-track:active {
    background: #f6682f;
}
 /* PARA DISPOSITIVOS MOBILES */
@media screen and (max-width: 639px) { 
    .containerScrollPrincipal::-webkit-scrollbar {
        width: 1px;     /* Tamaño del scroll en vertical */
        height: 0px;    /* Tamaño del scroll en horizontal */
        /* display: none;  Ocultar scroll */
    }
    
    .containerScrollPrincipal::-webkit-scrollbar-thumb {
        background: #a13f18;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    .containerScrollPrincipal::-webkit-scrollbar-thumb:hover {
        /* background: #ac5e53; */
        /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2); */
    }
    
    /* Cambiamos el fondo cuando esté en active */
    .containerScrollPrincipal::-webkit-scrollbar-thumb:active {
        /* background-color: #3e639b; */
    }
    .containerScrollPrincipal::-webkit-scrollbar-track {
        background: #f6682f;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo cuando esté en active o hover */
    .containerScrollPrincipal::-webkit-scrollbar-track:hover,
    .containerScrollPrincipal::-webkit-scrollbar-track:active {
        background: #f6682f;
    }

}

/* ICONO DENTRO DEL INPUT BUSQUEDA*/
.inputBusqueda {
    box-sizing: border-box;
    color: #191919;
    padding: 15px 15px 15px 35px;
    width: 90%;
}

.input-iconBusqueda {
    color: #5c5c5c;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 60%;
    transform: translateY(-50%);
}
.boton-iconBusqueda {
    /* color: #8b8181; */
    /* border:1px solid #420505; */
    /* background-color: #f6672f5d; */
    /* position: absolute; */
    /* width: 30px; */
    /* height: 30px; */
    /* right: 10px; */
    /* top: 1%; */
    /* padding: 5px; */
    transform: translateY(-50%);
}
/* FIN DEL ESTILO DEL SCROLLBAR */


/**INPUT FILE***/

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    border-radius: 10px;
    max-width: 70%;
    font-size: 1rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.25rem 0.5rem;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.iborrainputfile {
	font-size:16px; 
	font-weight:normal;
	/* font-family: 'Lato'; */
}

/* style 1 */

.inputfile-1 + label {
    color: #fff;
    background-color: #01630e;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    background-color: #149427;
}


/**********End File Inputs**********/

/* DIV MOIBLE */

.divMovible {
    position: absolute;
    z-index: 9;
}

.divMovibleCabecera {
    padding: 10px;
    cursor: move;
    z-index: 10;
}

/* FIN DIV MOBIBLE */

/* TABLE CSS */
@media screen and (min-width: 700px) {
    .tableResponsive {
        width: 100%;
        text-align: center;
        /* border-collapse: separate; */
        border-spacing: 0;

    }

    .tableResponsive tr th:first-child{
        /* ENCABEZADO REDONDEADO */
        /* border-top-left-radius: 10px; */

    }
    
    .tableResponsive tr th:last-child{
        /* ENCABEZADO REDONDEADO */
        /* border-top-right-radius: 10px;  */
    }

    .tableResponsive tr th {
        background: #f6682f ;
        color: white;
        font-size: 18px;
    }

    .tableResponsive thead tr {
        height: 40px;
    }
    
    tbody tr:nth-child(2n) {
        background-color: #dddddd;
    }

    .tableResponsive tbody tr {
        height: 70px;
        color: rgb(134, 134, 134);
    }


    .tableResponsive tbody tr:hover {
        background: #6990ad!important;
        cursor: pointer;
        color: white;
    }

    .tableResponsive tbody tr td {
        font-size: 17px;
        height: 15px;
        border-top: 1px solid #ddd;

    }

    .tableResponsive thead tr th:hover {
        cursor: pointer;
    }
    

    .tableResponsive tbody tr:nth-child(odd) {
        background-color: #ffffff;
    }

    .tableResponsive tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .tableResponsive > tbody > tr > td, .tableResponsive > tbody > tr > th, .tableResponsive > tfoot > tr > td, .tableResponsive > tfoot > tr > th, .tableResponsive > thead > tr > td, .tableResponsive > thead > tr > th {
        padding: 8px;
        padding-top: 3px;
        /* line-height: 1.42857143; */
        vertical-align: middle;
        padding-bottom: 0px;
    }

    .tableResponsive thead tr th { 
        position: sticky!important;
        top: 0!important;
        z-index: 10!important;
        /* border-bottom: 1px solid #000!important; */
    }

    .tableResponsive thead tr:nth-child(2) th { 
        top: 30px!important;
    }
}

.contenedor-tabla {
    height: calc(90% - 48px - 28px);
    overflow: auto;
}


@media screen and (max-width: 700px) {
    .tableResponsive {
        border: 0;
        /* border-radius: 20px; */
        /* border: 1px solid #ccc; */
        
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        /* table-layout: fixed; */
        color:#ffffff
    }

    .tableResponsive caption {
        font-size: 1.5em;
    }

    .tableResponsive th,
    .tableResponsive td {
        padding: .625em;
        text-align: center;
    }
    
    .tableResponsive thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .tableResponsive tr { /*CADA REGISTRO*/
        /* border-bottom: 3px solid #ddd; */
        border: 1px solid rgb(255, 255, 255);
        border-radius: 5px;
        padding-left: .3em;
        /* padding: .35em; */
        /* display: block; */
        margin-bottom: .6em;
        background: #181f2e;
        
        display: block;

        /* justify-content: center; */
        /* align-items: center; */

    }

    .tableResponsive td {
        /* border-bottom: 1px groove rgb(134, 131, 131); */
        display: block;
        font-size: 1em;
        width: 100%;
        text-align: right;
        
    }

    .tablenum{
        width: 20px !important;
        font-size: 1.5em !important;
        font-weight:  bolder;
        text-align: left;
        /* text-decoration: underline; */
    }
    .tableFotoCliente{
        position: absolute;
        margin-top: 10px;
    }
      
  .tdNombrePersona{
    font-size: 18px !important;
  }


    .tableopciones{

        margin:10px ;
        text-align: center;
        border: 1 solid white;
        
    }
    
    .tableResponsive td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-align: center;
        align-items: center;
        align-content: center;
        /* text-transform: uppercase; */
        
    }
    
    .tableResponsive td:last-child {
        border-bottom: 0;
        background: #0e131b;
    }
}

/* TABLE CSS LISTADO DE PRODUCTOS */
@media screen and (min-width: 700px) {
    .tableResponsiveProductos {
        width: 100%;
        text-align: center;
        /* border-collapse: separate; */
        border-spacing: 0;

    }

    .tableResponsiveProductos tr th:first-child{
        /* ENCABEZADO REDONDEADO */
        border-top-left-radius: 4px;
        
      }
      
      .tableResponsiveProductos tr th:last-child{
        /* ENCABEZADO REDONDEADO */
        border-top-right-radius: 1px; 
    }

    .tableResponsiveProductos tr th {
        background:#d34f1b;
        color: white;
        font-size: 18px;
    }

    .tableResponsiveProductos thead tr {
        height: 50px;
    }
    
    tbody tr:nth-child(2n) {
        background-color: #dddddd;
    }

    .tableResponsiveProductos tbody tr {
        height: 100px;
        color: #f0f0f0;
    }


    .tableResponsiveProductos tbody tr:hover {
        background: #6990ad!important;
        cursor: pointer;
        color: white;
    }

    .tableResponsiveProductos tbody tr td {
        font-size: 15px;
        height: 15px;
        border-top: 1px solid #ddd;

    }

    .tableResponsiveProductos thead tr th:hover {
        cursor: pointer;
    }
    

    .tableResponsiveProductos tbody tr:nth-child(odd) {
        background-color: #ffffff00;
    }

    .tableResponsiveProductos tbody tr:nth-child(even) {
        background-color: #ffffff00;
    }

    .tableResponsiveProductos > tbody > tr > td, .tableResponsiveProductos > tbody > tr > th, .tableResponsiveProductos > tfoot > tr > td, .tableResponsiveProductos > tfoot > tr > th, .tableResponsiveProductos > thead > tr > td, .tableResponsiveProductos > thead > tr > th {
        padding: 8px;
        padding-top: 3px;
        /* line-height: 1.42857143; */
        vertical-align: middle;
        padding-bottom: 0px;
    }

    .tableResponsiveProductos thead tr th { 
        position: sticky!important;
        top: 0!important;
        z-index: 10!important;
        /* border-bottom: 1px solid #000!important; */
    }

    .tableResponsiveProductos thead tr:nth-child(2) th { 
        top: 30px!important;
    }
}

.contenedor-tabla {
    height: calc(90% - 48px - 28px);
    overflow: auto;
}


@media screen and (max-width: 700px) {
    .tableResponsiveProductos {
        border: 0;
        /* border-radius: 20px; */
        /* border: 1px solid #ccc; */
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;

        /* table-layout: fixed; */
        color:#ffffff
    }

    /* .tableResponsiveProductos caption {
        font-size: 1.5em;
    } */

    .tableResponsiveProductos th,
    .tableResponsiveProductos td {
        /* padding: .625em; */
        /* padding: 0px; */
        /* text-align: center; */
    }
    
    .tableResponsiveProductos thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .tableResponsiveProductos tr { /*CADA REGISTRO*/
        /* border-bottom: 3px solid #ddd; */
        /* border: 1px solid rgb(255, 255, 255); */
        border-radius: 5px;
        /* padding-left: .3em; */
        /* padding: .35em; */
        display: block;
        margin-bottom: 1em;
        background: #131927;
        
        display: block;

        justify-content: center;
        align-items: center;

    }

    .tableResponsiveProductos td {
        display: block;
        font-size: 1em;
        width: 100%;
        text-align: center;
    }

    .tableFotoProducto{
        /* position: flex; */
        /* text-align: center; */
        /* align-items: center; */
        /* justify-content: center; */
        /* padding: 0px; */
        /* align-content: center; */
        /* align-self: center; */
        /* margin-top: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    }
      



    .tableopciones{
        text-align: center;
        border-top: 1px solid white;
        
    }
    
    .tableResponsiveProductos td::before {
        content: attr(data-label);
        float: center;
        font-weight: bold;
        text-align: center;
        align-items: center;
        align-content: center;
        /* text-transform: uppercase; */
        
    }
    
    .tableResponsiveProductos td:last-child {
        border-bottom: 0;
        background: #0a0d13;
    }
}





/* TABLE PRODUCTOS EN REGISTRO DE VENTASCSS */
@media screen and (min-width: 700px) {
    .tableResponsiveRegistroVenta {
        width: 100%;
        text-align: center;
        /* border-collapse: separate; */
        border-spacing: 0;

    }

    .tableResponsiveRegistroVenta tr th:first-child{
        /* ENCABEZADO REDONDEADO */
        /* border-top-left-radius: 10px; */

    }
    
    .tableResponsiveRegistroVenta tr th:last-child{
        /* ENCABEZADO REDONDEADO */
        /* border-top-right-radius: 10px;  */
    }

    .tableResponsiveRegistroVenta tr th {
        background: #f6682f ;
        color: white;
        font-size: 18px;
    }

    .tableResponsiveRegistroVenta thead tr {
        height: 40px;
    }
    
    tbody tr:nth-child(2n) {
        background-color: #dddddd;
    }

    .tableResponsiveRegistroVenta tbody tr {
        height: 70px;
        color: rgb(134, 134, 134);
    }


    .tableResponsiveRegistroVenta tbody tr:hover {
        background: #6990ad!important;
        cursor: pointer;
        color: white;
    }

    .tableResponsiveRegistroVenta tbody tr td {
        font-size: 17px;
        height: 15px;
        border-top: 1px solid #ddd;

    }

    .tableResponsiveRegistroVenta thead tr th:hover {
        cursor: pointer;
    }
    

    .tableResponsiveRegistroVenta tbody tr:nth-child(odd) {
        background-color: #ffffff;
    }

    .tableResponsiveRegistroVenta tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .tableResponsiveRegistroVenta > tbody > tr > td, .tableResponsiveRegistroVenta > tbody > tr > th, .tableResponsiveRegistroVenta > tfoot > tr > td, .tableResponsiveRegistroVenta > tfoot > tr > th, .tableResponsiveRegistroVenta > thead > tr > td, .tableResponsiveRegistroVenta > thead > tr > th {
        padding: 8px;
        padding-top: 3px;
        /* line-height: 1.42857143; */
        vertical-align: middle;
        padding-bottom: 0px;
    }

    .tableResponsiveRegistroVenta thead tr th { 
        position: sticky!important;
        top: 0!important;
        z-index: 10!important;
        /* border-bottom: 1px solid #000!important; */
    }

    .tableResponsiveRegistroVenta thead tr:nth-child(2) th { 
        top: 30px!important;
    }
}

.contenedor-tabla {
    height: calc(90% - 48px - 28px);
    overflow: auto;
}


@media screen and (max-width: 700px) {
    .tableResponsiveRegistroVenta {
        border: 0;
        /* border-radius: 20px; */
        /* border: 1px solid #ccc; */
        
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        /* table-layout: fixed; */
        color:#ffffff
    }

    .tableResponsiveRegistroVenta caption {
        font-size: 1.5em;
    }

    .tableResponsiveRegistroVenta th,
    .tableResponsiveRegistroVenta td {
        padding: .625em;
        text-align: center;
    }
    
    .tableResponsiveRegistroVenta thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .tableResponsiveRegistroVenta tr { /*CADA REGISTRO*/
        /* border-bottom: 3px solid #ddd; */
        border: 1px solid rgb(255, 255, 255);
        border-radius: 5px;
        padding-left: .3em;
        /* padding: .35em; */
        /* display: block; */
        margin-bottom: .6em;
        background: #181f2e;
        
        display: block;

        /* justify-content: center; */
        /* align-items: center; */

    }

    .tableResponsiveRegistroVenta td {
        /* border-bottom: 1px groove rgb(134, 131, 131); */
        display: block;
        font-size: 1em;
        width: 100%;
        text-align: right;
        
    }

    .tablenum{
        width: 20px !important;
        font-size: 1.5em !important;
        font-weight:  bolder;
        text-align: left;
        /* text-decoration: underline; */
    }
    .tableFotoCliente{
        position: absolute;
        margin-top: 10px;
    }
      
  .tdNombrePersona{
    font-size: 18px !important;
  }


    .tableopciones{

        margin:10px ;
        text-align: center;
        border: 1 solid white;
        
    }
    
    .tableResponsiveRegistroVenta td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-align: center;
        align-items: center;
        align-content: center;
        /* text-transform: uppercase; */
        
    }
    
    .tableResponsiveRegistroVenta td:last-child {
        border-bottom: 0;
        background: #0e131b;
    }
}

/* TABLE PRODUCTOS EN REGISTRO DE VENTASCSS */
@media screen and (min-width: 700px) {
    .tableResponsiveClientesRegistroVenta {
        width: 100%;
        text-align: center;
        /* border-collapse: separate; */
        border-spacing: 0;

    }

    .tableResponsiveClientesRegistroVenta tr th:first-child{
        /* ENCABEZADO REDONDEADO */
        /* border-top-left-radius: 10px; */

    }
    
    .tableResponsiveClientesRegistroVenta tr th:last-child{
        /* ENCABEZADO REDONDEADO */
        /* border-top-right-radius: 10px;  */
    }

    .tableResponsiveClientesRegistroVenta tr th {
        background: #f6682f ;
        color: white;
        font-size: 18px;
    }

    .tableResponsiveClientesRegistroVenta thead tr {
        height: 40px;
    }
    
    tbody tr:nth-child(2n) {
        background-color: #dddddd;
    }

    .tableResponsiveClientesRegistroVenta tbody tr {
        height: 70px;
        color: rgb(134, 134, 134);
    }


    .tableResponsiveClientesRegistroVenta tbody tr:hover {
        background: #6990ad!important;
        cursor: pointer;
        color: white;
    }

    .tableResponsiveClientesRegistroVenta tbody tr td {
        font-size: 17px;
        height: 15px;
        border-top: 1px solid #ddd;

    }

    .tableResponsiveClientesRegistroVenta thead tr th:hover {
        cursor: pointer;
    }
    

    .tableResponsiveClientesRegistroVenta tbody tr:nth-child(odd) {
        background-color: #ffffff;
    }

    .tableResponsiveClientesRegistroVenta tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .tableResponsiveClientesRegistroVenta > tbody > tr > td, .tableResponsiveClientesRegistroVenta > tbody > tr > th, .tableResponsiveClientesRegistroVenta > tfoot > tr > td, .tableResponsiveClientesRegistroVenta > tfoot > tr > th, .tableResponsiveClientesRegistroVenta > thead > tr > td, .tableResponsiveClientesRegistroVenta > thead > tr > th {
        padding: 8px;
        padding-top: 3px;
        /* line-height: 1.42857143; */
        vertical-align: middle;
        padding-bottom: 0px;
    }

    .tableResponsiveClientesRegistroVenta thead tr th { 
        position: sticky!important;
        top: 0!important;
        z-index: 10!important;
        /* border-bottom: 1px solid #000!important; */
    }

    .tableResponsiveClientesRegistroVenta thead tr:nth-child(2) th { 
        top: 30px!important;
    }
}

.contenedor-tabla {
    height: calc(90% - 48px - 28px);
    overflow: auto;
}


@media screen and (max-width: 700px) {
    .tableResponsiveClientesRegistroVenta {
        border: 0;
        /* border-radius: 20px; */
        /* border: 1px solid #ccc; */
        
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        /* table-layout: fixed; */
        color:#ffffff
    }

    .tableResponsiveClientesRegistroVenta caption {
        font-size: 1.5em;
    }

    .tableResponsiveClientesRegistroVenta th,
    .tableResponsiveClientesRegistroVenta td {
        padding: .625em;
        text-align: center;
    }
    
    .tableResponsiveClientesRegistroVenta thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .tableResponsiveClientesRegistroVenta tr { /*CADA REGISTRO*/
        /* border-bottom: 3px solid #ddd; */
        border: 1px solid rgb(255, 255, 255);
        border-radius: 5px;
        padding-left: .3em;
        /* padding: .35em; */
        /* display: block; */
        margin-bottom: .6em;
        background: #0e131b;
        
        display: block;

        /* justify-content: center; */
        /* align-items: center; */

    }

    .tableResponsiveClientesRegistroVenta td {
        /* border-bottom: 1px groove rgb(134, 131, 131); */
        display: block;
        font-size: 1em;
        width: 100%;
        text-align: right;
        
    }

    .tablenum{
        width: 20px !important;
        font-size: 1.5em !important;
        font-weight:  bolder;
        text-align: left;
        /* text-decoration: underline; */
    }
    .tableFotoCliente{
        position: absolute;
        margin-top: 10px;
    }
      
  .tdNombrePersona{
    font-size: 18px !important;
  }


    .tableopciones{

        margin:10px ;
        text-align: center;
        border: 1 solid white;
        
    }
    
    .tableResponsiveClientesRegistroVenta td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-align: center;
        align-items: center;
        align-content: center;
        /* text-transform: uppercase; */
        
    }
    
    .tableResponsiveClientesRegistroVenta td:last-child {
        border-bottom: 0;
        background: #000000;
    }
}

/* TABLE CSS VOCHER */
@media screen and (min-width: 700px) {
  .tableResponsiveVoucher {
      width: 100%;
      text-align: center;
      /* border-collapse: separate; */
      border-spacing: 0;

  }

  .tableResponsiveVoucher tr th:first-child{
      /* ENCABEZADO REDONDEADO */
      /* border-top-left-radius: 10px; */

  }
  
  .tableResponsiveVoucher tr th:last-child{
      /* ENCABEZADO REDONDEADO */
      /* border-top-right-radius: 10px;  */
  }

  .tableResponsiveVoucher tr th {
      background: #f6682f ;
      color: white;
      font-size: 18px;
  }

  .tableResponsiveVoucher thead tr {
      height: 40px;
  }
  
  tbody tr:nth-child(2n) {
      background-color: #dddddd;
  }

  .tableResponsiveVoucher tbody tr {
      height: 70px;
      color: rgb(134, 134, 134);
  }


  .tableResponsiveVoucher tbody tr:hover {
      background: #6990ad!important;
      cursor: pointer;
      color: white;
  }

  .tableResponsiveVoucher tbody tr td {
      font-size: 17px;
      height: 15px;
      border-top: 1px solid #ddd;

  }

  .tableResponsiveVoucher thead tr th:hover {
      cursor: pointer;
  }
  

  .tableResponsiveVoucher tbody tr:nth-child(odd) {
      background-color: #ffffff;
  }

  .tableResponsiveVoucher tbody tr:nth-child(even) {
      background-color: #ffffff;
  }

  .tableResponsiveVoucher > tbody > tr > td, .tableResponsiveVoucher > tbody > tr > th, .tableResponsiveVoucher > tfoot > tr > td, .tableResponsiveVoucher > tfoot > tr > th, .tableResponsiveVoucher > thead > tr > td, .tableResponsiveVoucher > thead > tr > th {
      padding: 8px;
      padding-top: 3px;
      /* line-height: 1.42857143; */
      vertical-align: middle;
      padding-bottom: 0px;
  }

  .tableResponsiveVoucher thead tr th { 
      position: sticky!important;
      top: 0!important;
      z-index: 10!important;
      /* border-bottom: 1px solid #000!important; */
  }

  .tableResponsiveVoucher thead tr:nth-child(2) th { 
      top: 30px!important;
  }
}

.contenedor-tabla {
  height: calc(90% - 48px - 28px);
  overflow: auto;
}


@media screen and (max-width: 700px) {
  .tableResponsiveVoucher {
      border: 0;
      /* border-radius: 20px; */
      /* border: 1px solid #ccc; */
      
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: 100%;
      /* table-layout: fixed; */
      color:#ffffff
  }

  .tableResponsiveVoucher caption {
      font-size: 3em ;
  }

  .tableResponsiveVoucher th,
  .tableResponsiveVoucher td {
      padding: .625em;
      text-align: center;
  }
  
  .tableResponsiveVoucher thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
  }

  .tableResponsiveVoucher tr { /*CADA REGISTRO*/
      /* border-bottom: 3px solid #ddd; */
      border: 1px solid rgb(255, 255, 255);
      border-radius: 5px;
      padding-left: .3em;
      /* padding: .35em; */
      /* display: block; */
      margin-bottom: .6em;
      background: #181f2e;
      
      display: block;

      /* justify-content: center; */
      /* align-items: center; */

  }

  .tableResponsiveVoucher td {
    /* border-bottom: 1px groove rgb(134, 131, 131); */
      display: block;
      font-size: 1.2em;
      width: 100%;
      text-align: center;
      
  }

  .tablenum{
      width: 20px !important;
      font-size: 1.5em !important;
      font-weight:  bolder;
      /* text-align: center ; */
      /* text-decoration: underline; */
  }
  .tableFotoCliente{
      position: absolute;
      margin-top: 10px;
  }
    
.tdNombrePersona{
  font-size: 18px !important;
}


  .tableopciones{

      margin:10px ;
      text-align: center;
      border: 1 solid white;
      
  }
  
  .tableResponsiveVoucher td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-align: center;
      align-items: center;
      align-content: center;
      text-transform: uppercase;
      
  }
  
  .tableResponsiveVoucher td:last-child {
      border-bottom: 0;
      background: #0e131b;
  }
}





/* INPUTS DE REGISTRO */

.form {
    /* width: 320px; */
    /* margin: 30px auto; */
  }
  
  
  .group {
    position: relative;
    margin: 10px 0;
  }
  
  .textareaRegistro {
    resize: vertical;
    height: 80px;
  }
  
  .inputRegistro,
  .textareaRegistro {
    background: none;
    color: #4d4d4d;
    /* font-size: 20px; */
    text-align: center;
    padding: 7px 3px 1px 1px;
    display: block;
    width: 320px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #b1b1b1;
  }
  .inputRegistro:focus,
  .textareaRegistro:focus {
    outline: none;
    caret-color: #818181;
    /* caret-color: #f6682f; */

  }
  .inputRegistro:focus ~ .labelRegistro, .inputRegistro:valid ~ .labelRegistro,
  .textareaRegistro:focus ~ .labelRegistro,
  .textareaRegistro:valid ~ .labelRegistro {
    top: -8px;
    font-size: 13px;
    color: #f6682f;

  }
  .inputRegistro:focus ~ .bar:before,
  .textareaRegistro:focus ~ .bar:before {
    width: 100%;
  }
  
  .inputRegistro[type=password] {
    letter-spacing: 0.3em;
  }
  
  .labelRegistro {
    color: #4d4d4d;
    font-size: 17px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 400ms ease all;
  }
  
  .bar {
    position: relative;
    display: block;
    width: 320px;
  }
  .bar:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #f6682f;
    transition: 600ms ease all;
    left: 0%;
  }




/* ///////////////////FILTROSSSSSSSSSSSSSSSSSSS//////////////// */


  .light:root {
    --app-bg: #fff;
    --sidebar: #f3f6fd;
    --app-content-secondary-color: #f3f6fd;
    --app-content-main-color: #1f1c2e;
    --sidebar-link: #1f1c2e;
    --sidebar-hover-link: rgba(195, 207, 244, 0.5);
    --sidebar-active-link: rgba(195, 207, 244, 1);
    --sidebar-main-color: #1f1c2e;
    --filter-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  
  body {
    overflow: hidden;
    font-family: "Poppins", sans-serif;
    background-color: var(--app-bg);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .app-container {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 1280px;
    display: flex;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-width: 2000px;
    margin: 0 auto;
  }
  
  .sidebar {
    flex-basis: 200px;
    max-width: 200px;
    flex-shrink: 0;
    background-color: var(--sidebar);
    display: flex;
    flex-direction: column;
  }
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  .sidebar-list {
    list-style-type: none;
    padding: 0;
  }
  .sidebar-list-item {
    position: relative;
    margin-bottom: 4px;
  }
  .sidebar-list-item a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 16px;
    color: var(--sidebar-link);
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
  }
  .sidebar-list-item svg {
    margin-right: 8px;
  }
  .sidebar-list-item:hover {
    background-color: var(--sidebar-hover-link);
  }
  .sidebar-list-item.active {
    background-color: var(--sidebar-active-link);
  }
  .sidebar-list-item.active:before {
    content: "";
    position: absolute;
    right: 0;
    background-color: var(--action-color);
    height: 100%;
    width: 4px;
  }
  @media screen and (max-width: 1024px) {
    .sidebar {
      display: none;
    }
  }
  
  .mode-switch {
    background-color: transparent;
    border: none;
    padding: 0;
    color: var(--app-content-main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 8px;
    cursor: pointer;
  }
  .mode-switch .moon {
    fill: var(--app-content-main-color);
  }
  
  .mode-switch.active .moon {
    fill: none;
  }
  
  .account-info {
    display: flex;
    align-items: center;
    padding: 16px;
    margin-top: auto;
  }
  .account-info-picture {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  .account-info-picture img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .account-info-name {
    font-size: 14px;
    color: var(--sidebar-main-color);
    margin: 0 8px;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .account-info-more {
    color: var(--sidebar-main-color);
    padding: 0;
    border: none;
    background-color: transparent;
    margin-left: auto;
  }
  
  .app-icon {
    color: var(--sidebar-main-color);
  }
  .app-icon svg {
    width: 24px;
    height: 24px;
  }
  
  .app-content {
    padding: 16px;
    background-color: var(--app-bg);
    height: 100%;
    flex: 1;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .app-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
  }
  .app-content-headerText {
    color: var(--app-content-main-color);
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
  .app-content-headerButton {
    background-color: var(--action-color);
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    border: none;
    border-radius: 4px;
    height: 32px;
    padding: 0 16px;
    transition: 0.2s;
    cursor: pointer;
  }
  .app-content-headerButton:hover {
    background-color: var(--action-color-hover);
  }
  .app-content-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 4px;
  }
  .app-content-actions-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  @media screen and (max-width: 520px) {
    .app-content-actions {
      flex-direction: column;
    }
    .app-content-actions .search-bar {
      max-width: 100%;
      order: 2;
    }
    .app-content-actions .app-content-actions-wrapper {
      padding-bottom: 16px;
      order: 1;
    }
  }
  
  .search-bar {
    background-color: var(--app-content-secondary-color);
    border: 1px solid var(--app-content-secondary-color);
    color: var(--app-content-main-color);
    font-size: 14px;
    line-height: 24px;
    border-radius: 4px;
    padding: 0px 10px 0px 32px;
    height: 32px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'/%3E%3C/svg%3E");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: left 10px center;
    width: 100%;
    max-width: 320px;
    transition: 0.2s;
  }
  .light .search-bar {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%231f1c2e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'/%3E%3C/svg%3E");
  }
  .search-bar:placeholder {
    color: var(--app-content-main-color);
  }
  .search-bar:hover {
    border-color: var(--action-color-hover);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236291fd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'/%3E%3C/svg%3E");
  }
  .search-bar:focus {
    outline: none;
    border-color: var(--action-color);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%232869ff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'/%3E%3C/svg%3E");
  }
  
  .action-button {
    border-radius: 4px;
    height: 32px;
    background-color: var(--app-content-secondary-color);
    border: 1px solid var(--app-content-secondary-color);
    display: flex;
    align-items: center;
    color: var(--app-content-main-color);
    font-size: 14px;
    margin-left: 8px;
    cursor: pointer;
  }
  .action-button span {
    margin-right: 4px;
  }
  .action-button:hover {
    border-color: var(--action-color-hover);
  }
  .action-button:focus, .action-button.active {
    outline: none;
    color: var(--action-color);
    border-color: var(--action-color);
  }
  
  .filter-button-wrapper {
    position: relative;
  }
  
  .filter-menu {
    background-color: var(--app-content-secondary-color);
    position: absolute;
    top: calc(100% + 16px);
    right: -74px;
    border-radius: 4px;
    padding: 8px;
    width: 220px;
    z-index: 2;
    box-shadow: var(--filter-shadow);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
  }
  .filter-menu:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--app-content-secondary-color);
    bottom: 100%;
    left: 50%;
    transform: translatex(-50%);
  }
  .filter-menu.active {
    visibility: visible;
    opacity: 1;
    top: calc(100% + 8px);
  }
  .filter-menu label {
    display: block;
    font-size: 14px;
    color: var(--app-content-main-color);
    margin-bottom: 8px;
  }
  .filter-menu select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    padding: 8px 24px 8px 8px;
    background-position: right 4px center;
    border: 1px solid var(--app-content-main-color);
    border-radius: 4px;
    color: var(--app-content-main-color);
    font-size: 12px;
    background-color: transparent;
    margin-bottom: 16px;
    width: 100%;
  }
  .filter-menu select option {
    font-size: 14px;
  }
  .light .filter-menu select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%231f1c2e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  }
  .filter-menu select:hover {
    border-color: var(--action-color-hover);
  }
  .filter-menu select:focus, .filter-menu select.active {
    outline: none;
    color: var(--action-color);
    border-color: var(--action-color);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%232869ff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  }
  
  .filter-menu-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .filter-button {
    border-radius: 2px;
    font-size: 12px;
    padding: 4px 8px;
    cursor: pointer;
    border: none;
    color: #fff;
  }
  .filter-button.apply {
    background-color: var(--action-color);
  }
  .filter-button.reset {
    background-color: var(--filter-reset);
  }
  
  .products-area-wrapper {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 0 4px;
  }
  
  .tableView .products-header {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--app-content-secondary-color);
    position: sticky;
    top: 0;
  }
  .tableView .products-row {
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  .tableView .products-row:hover {
    box-shadow: var(--filter-shadow);
    background-color: var(--app-content-secondary-color);
  }
  .tableView .products-row .cell-more-button {
    display: none;
  }
  .tableView .product-cell {
    flex: 1;
    padding: 8px 16px;
    color: var(--app-content-main-color);
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .tableView .product-cell img {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    margin-right: 6px;
  }
  @media screen and (max-width: 780px) {
    .tableView .product-cell {
      font-size: 12px;
    }
    .tableView .product-cell.image span {
      display: none;
    }
    .tableView .product-cell.image {
      flex: 0.2;
    }
  }
  @media screen and (max-width: 520px) {
    .tableView .product-cell.category, .tableView .product-cell.sales {
      display: none;
    }
    .tableView .product-cell.status-cell {
      flex: 0.4;
    }
    .tableView .product-cell.stock, .tableView .product-cell.price {
      flex: 0.2;
    }
  }
  @media screen and (max-width: 480px) {
    .tableView .product-cell.stock {
      display: none;
    }
    .tableView .product-cell.price {
      flex: 0.4;
    }
  }
  .tableView .sort-button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--app-content-main-color);
    margin-left: 4px;
    display: flex;
    align-items: center;
  }
  .tableView .sort-button:hover {
    color: var(--action-color);
  }
  .tableView .sort-button svg {
    width: 12px;
  }
  .tableView .cell-label {
    display: none;
  }
  
  .status {
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 12px;
  }
  .status:before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 4px;
  }
  .status.active {
    color: #2ba972;
    background-color: rgba(43, 169, 114, 0.2);
  }
  .status.active:before {
    background-color: #2ba972;
  }
  .status.disabled {
    color: #59719d;
    background-color: rgba(89, 113, 157, 0.2);
  }
  .status.disabled:before {
    background-color: #59719d;
  }
  
  .gridView {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }
  @media screen and (max-width: 520px) {
    .gridView {
      margin: 0;
    }
  }
  .gridView .products-header {
    display: none;
  }
  .gridView .products-row {
    margin: 8px;
    width: calc(25% - 16px);
    background-color: var(--app-content-secondary-color);
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s;
    position: relative;
  }
  .gridView .products-row:hover {
    transform: scale(1.01);
    box-shadow: var(--filter-shadow);
  }
  .gridView .products-row:hover .cell-more-button {
    display: flex;
  }
  @media screen and (max-width: 1024px) {
    .gridView .products-row {
      width: calc(33.3% - 16px);
    }
  }
  @media screen and (max-width: 820px) {
    .gridView .products-row {
      width: calc(50% - 16px);
    }
  }
  @media screen and (max-width: 520px) {
    .gridView .products-row {
      width: 100%;
      margin: 8px 0;
    }
    .gridView .products-row:hover {
      transform: none;
    }
  }
  .gridView .products-row .cell-more-button {
    border: none;
    padding: 0;
    border-radius: 4px;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: rgba(16, 24, 39, 0.7);
    color: #fff;
    cursor: pointer;
    display: none;
  }
  .gridView .product-cell {
    color: var(--app-content-main-color);
    font-size: 14px;
    margin-bottom: 8px;
  }
  .gridView .product-cell:not(.image) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .gridView .product-cell.image span {
    font-size: 18px;
    line-height: 24px;
  }
  .gridView .product-cell img {
    width: 100%;
    height: 140px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  .gridView .cell-label {
    opacity: 0.6;
  }






  .wrapperP {
    width: 300px;
    height: 500px;
    background: white;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0;
    transform: scale(0.95);
    transition: box-shadow 0.5s, transform 0.5s;
  }
  .wrapperP:hover {
    transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
  }
  .wrapperP .container {
    width: 100%;
    height: 100%;
  }
  .wrapperP .container .top {
    height: 80%;
    width: 100%;
    /* background: url(https://s-media-cache-ak0.pinimg.com/736x/49/80/6f/49806f3f1c7483093855ebca1b8ae2c4.jpg) no-repeat center center; */
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
  }
  .wrapperP .container .bottom {
    width: 200%;
    height: 20%;
    transition: transform 0.5s;
  }
  .wrapperP .container .bottom.clicked {
    transform: translateX(-50%);
  }
  .wrapperP .container .bottom h1 {
    margin: 0;
    padding: 0;
  }
  .wrapperP .container .bottom p {
    margin: 0;
    padding: 0;
  }
  .wrapperP .container .bottom .left {
    height: 100%;
    width: 50%;
    background: #f4f4f4;
    position: relative;
    float: left;
  }
  .wrapperP .container .bottom .left .details {
    padding: 20px;
    float: left;
    width: calc(70% - 40px);
  }
  .wrapperP .container .bottom .left .buy {
    float: right;
    width: calc(30% - 2px);
    height: 100%;
    background: #f1f1f1;
    transition: background 0.5s;
    border-left: solid thin rgba(0, 0, 0, 0.1);
  }
  .wrapperP .container .bottom .left .buy i {
    font-size: 30px;
    padding: 30px;
    color: #254053;
    transition: transform 0.5s;
  }
  .wrapperP .container .bottom .left .buy:hover {
    background: #a6cdde;
  }
  .wrapperP .container .bottom .left .buy:hover i {
    transform: translateY(5px);
    color: #00394b;
  }
  .wrapperP .container .bottom .right {
    width: 50%;
    background: #a6cdde;
    color: white;
    float: right;
    height: 200%;
    overflow: hidden;
  }
  .wrapperP .container .bottom .right .details {
    padding: 20px;
    float: right;
    width: calc(70% - 40px);
  }
  .wrapperP .container .bottom .right .done {
    width: calc(30% - 2px);
    float: left;
    transition: transform 0.5s;
    border-right: solid thin rgba(255, 255, 255, 0.3);
    height: 50%;
  }
  .wrapperP .container .bottom .right .done i {
    font-size: 30px;
    padding: 30px;
    color: white;
  }
  .wrapperP .container .bottom .right .remove {
    width: calc(30% - 1px);
    clear: both;
    border-right: solid thin rgba(255, 255, 255, 0.3);
    height: 50%;
    background: #bc3b59;
    transition: transform 0.5s, background 0.5s;
  }
  .wrapperP .container .bottom .right .remove:hover {
    background: #9b2847;
  }
  .wrapperP .container .bottom .right .remove:hover i {
    transform: translateY(5px);
  }
  .wrapperP .container .bottom .right .remove i {
    transition: transform 0.5s;
    font-size: 30px;
    padding: 30px;
    color: white;
  }
  .wrapperP .container .bottom .right:hover .remove,
  .wrapperP .container .bottom .right:hover .done {
    transform: translateY(-100%);
  }
  .wrapperP .inside {
    z-index: 9;
    background: #92879b;
    width: 140px;
    height: 140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    transition: all 0.5s, border-radius 2s, top 1s;
    overflow: hidden;
  }
  .wrapperP .inside .icon {
    position: absolute;
    right: 85px;
    top: 85px;
    color: white;
    opacity: 1;
  }
  .wrapperP .inside:hover {
    width: 100%;
    right: 0;
    top: 0;
    border-radius: 0;
    height: 80%;
  }
  .wrapperP .inside:hover .icon {
    opacity: 0;
    right: 15px;
    top: 15px;
  }
  .wrapperP .inside:hover .contents {
    opacity: 1;
    transform: scale(1);
    transform: translateY(0);
  }
  .wrapperP .inside .contents {
    padding: 5%;
    opacity: 0;
    transform: scale(0.5);
    transform: translateY(-200%);
    transition: opacity 0.2s, transform 0.8s;
  }
  .wrapperP .inside .contents table {
    text-align: left;
    width: 100%;
  }
  .wrapperP .inside .contents h1,
  .wrapperP .inside .contents p,
  .wrapperP .inside .contents table {
    color: white;
  }
  .wrapperP .inside .contents p {
    font-size: 13px;
  }


/* Asegúrate de agregar estas clases a tu archivo de Tailwind CSS */
.spinner-border {
  border-top-color: transparent;
  border-right-color: inherit;
  border-bottom-color: inherit;
  border-left-color: inherit;
}

