
.card {
    margin: 10px ;
    width: 300px;
    height: 100px;
    border-radius: 20px;
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    cursor: pointer;
    transition: 0.4s;
}

.cardMonto {
    text-align: left;
    margin-left: 30px;
    /* border-radius: 0px 0px 40px 40px; */
    font-weight: bold;
    font-size: 30px;
    /* margin-top: -30px; */
    /* height: 40px; */
}
.cardTitulo {
    text-align: left;
    /* border-radius: 0px 0px 40px 40px; */
    font-weight: bold;
    font-size: 20px;
    /* height: 40px; */
}


.card:hover {
/* transform: scale(0.9, 0.9); */
box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
    -5px -5px 30px 15px rgba(0,0,0,0.22);
}

/* @media all and (max-width: 500px) {
    .card-list {
        flex-direction: column;
    }
} */











/* 
  .skills {
    animation-name: skills;
  }
  html {
    font-size: 100%;
    font-family: sans-serif;
  }
  body {
    background: #5aa8e8;
  }
  html,
  body,
  main {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  main {
    overflow: hidden;
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  section {
    background: #5aa8e8;
  }
  section article.skills {
    width: 500px;
    height: auto;
  }
  section article.skills p {
    z-index: 2;
    color: #fff;
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
  }
  section article.skills div span:nth-child(1) {
    z-index: -2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #fff;
    height: 100%;
    width: 100%;
  }
  section article.skills div span:nth-child(2) {
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #5E95E8;
    height: 100%;
  }
  section article.skills div:nth-child(1) span:nth-child(2) {
    width: 90%;
    animation-delay: 0;
  }
  section article.skills div:nth-child(2) span:nth-child(2) {
    width: 90%;
    animation-delay: 0.05s;
  }
  section article.skills div:nth-child(3) span:nth-child(2) {
    width: 80%;
    animation-delay: 0.1s;
  }
  section article.skills div:nth-child(4) span:nth-child(2) {
    width: 70%;
    animation-delay: 0.15s;
  }
  section article.skills div:nth-child(5) span:nth-child(2) {
    width: 75%;
    animation-delay: 0.2s;
  }
  section article.skills div:nth-child(6) span:nth-child(2) {
    width: 60%;
    animation-delay: 0.25s;
  }
  section article.skills div:nth-child(7) span:nth-child(2) {
    width: 80%;
    animation-delay: 0.3s;
  }
  section article.skills div:nth-child(8) span:nth-child(2) {
    width: 75%;
    animation-delay: 0.35s;
  }
  section:before {
    position: absolute;
    top: -250px;
    left: -350px;
    width: 500px;
    height: 500px;
    content: '';
    background: rgba(108, 90, 232, 0.25);
    transform: rotate(30deg);
  }
  section:after {
    position: absolute;
    bottom: -250px;
    right: -350px;
    width: 500px;
    height: 500px;
    content: '';
    background: rgba(108, 90, 232, 0.25);
    transform: rotate(30deg);
  }
  .skills {
    animation: skills 1.25s cubic-bezier(0.17, 0.67, 0, 1);
  }
  @keyframes skills {
    0% {
      left: -500px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
   */