body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.react-datepicker-popper {
  z-index: 100000000000000000000000000!important;
}

.react-datepicker__month-container {
  background: #ffffff!important;
}

.react-datepicker__header  {
  background: rgb(221, 221, 221)!important;
  border-bottom: 1px solid #424242;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: rgb(41, 41, 41)
}

.react-datepicker {
  border: 1px solid #3e3e3e
}

.react-datepicker__day:hover {
  /* background: #565656 */
  color: #424242;
}

.react-datepicker__day--selected:hover {
  color: #fff;
}