    

    /* Mixins */
/* bg shortcodes */
.bg-gradient1 span,
.bg-gradient1:before {
    background: #fa7e29;
    background: linear-gradient(180deg, #fa7e29 0%, #f6682f 80%, #f6682f 100%);
}

.buttonNuevo {
    text-decoration: none;
}
.buttonNuevo:hover, .buttonNuevo:focus, .buttonNuevo:active {
    text-decoration: none;
}

/* fancy Button */
.fancy-button {
    display: inline-block;
    margin:5px;
    /* font-family: "Heebo", Helvetica, Arial, sans-serif; */
    font-weight: 500;
    font-size: 15px;
    /* letter-spacing: 0.07em; */
    text-transform: uppercase;
    /* line-height: 24px; */
    color: #ffffff;
    position: relative;
}
.fancy-button.bg-gradient1 {
    text-shadow: 10px 10px 10px #bf4c28;
}

.fancy-button:before {
    content: "";
    display: inline-block;
    height: 40px;
    position: absolute;
    bottom: -1px;
    left: 10px;
    right: 10px;
    z-index: -1;
    /* border-radius: 2em; */
    filter: blur(14px) brightness(0.9);
    transform-style: preserve-3d;
    transition: all 0.3s ease-out;
}
.fancy-button i {
    margin-top: -2px;
    font-size: 1.265em;
    vertical-align: middle;
}
.fancy-button span {
    display: inline-block;
    padding: 10px 15px;
    /* border-radius: 40em; */
    position: relative;
    z-index: 2;
    will-change: transform, filter;
    transform-style: preserve-3d;
    transition: all 0.3s ease-out;
}
.fancy-button:focus {
    color: #ffffff;
}
.fancy-button:hover {
    color: #ffffff;
}
.fancy-button:hover span {
    filter: brightness(0.9) contrast(1.2);
    transform: scale(0.96);
}
.fancy-button:hover:before {
    bottom: 3px;
    filter: blur(6px) brightness(0.8);
}
.fancy-button:active span {
    filter: brightness(0.75) contrast(1.7);
}
.fancy-button.pop-onhover span {
    border-radius: 4px;
}
.fancy-button.pop-onhover:before {
    opacity: 0;
    bottom: 10px;
}
.fancy-button.pop-onhover:hover:before {
    bottom: -7px;
    opacity: 1;
    filter: blur(16px);
}
.fancy-button.pop-onhover:hover span {
    transform: scale(1);
}
.fancy-button.pop-onhover:hover:active span {
    filter: brightness(1) contrast(1);
    transform: scale(1);
    transition: all 0.2s ease-out;
}
.fancy-button.pop-onhover:hover:active:before {
    bottom: 0;
    filter: blur(5px) brightness(0.85);
    transition: all 0.2s ease-out;
}


.botonNuevo {
    background: #fa7e29;
    background: linear-gradient(180deg, #fa7e29 0%, #f6682f 80%, #f6682f 100%);    
    /* border-radius: 50px; */
    text-align:center;
    caret-color: transparent;
    /* border:1px solid #fff; */
}
.btn-circle.btn-xl {
    /* border-radius: 35px; */
    /* margin-bottom: 5px; */
}
.btn-circle {
    width: auto;
    /* height: 35px; */
    /* padding: 6px 6px 6px 6px; */
    /* border-radius: 15px; */
    text-align: center;
    font-size: 15px;
    /* line-height: 1.42857; */
}
.botonNuevo:hover{
    background-color: #ff3c00;
    box-shadow: 0 0 10px 0 rgb(193, 193, 193); 
}

