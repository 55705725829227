@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.859); /* Fondo semitransparente, ajusta según necesidades */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ajusta según necesidades para estar por encima de otros elementos */
}

.textCargando {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 1001;
  animation: bounce 1.5s infinite;
}






.textCargando {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Ajusta el tamaño de la fuente según tus preferencias */
  z-index: 1001; /* O cualquier valor que asegure que esté encima de otros elementos */
  /* animation: bounce 1.5s infinite;  */

}

.textCargando span {
  display: inline-block;
  opacity: 0;
  animation: appear 1s ease-out forwards, fire 2s ease-out infinite 1s; /* Agregué un retraso inicial */
}





@keyframes scaleUpDown {
  0%,
  100% {
    transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    transform: scaleY(1.1);
  }
  75% {
    transform: scaleY(0.95);
  }
  80% {
    transform: scaleX(0.95);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: skewX(0) scale(1);
  }
  50% {
    transform: skewX(5deg) scale(0.9);
  }
}

@keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    transform: scale(0.5);
  }
}

@keyframes glow {
  0%,
  100% {
    background-color: #ef5a00;
  }
  50% {
    background-color: #ff7800;
  }
}

.fire {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

.fire-center {
  position: absolute;
  height: 100%;
  width: 100%;
  animation: scaleUpDown 3s ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  .main-fire {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      farthest-corner at 10px 0,
      #d43300 0%,
      #ef5a00 95%
    );
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }

  .particle-fire {
    position: absolute;
    top: 60%;
    left: 45%;
    width: 10px;
    height: 10px;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  
  .particle-fire2 {
    position: absolute;
    top: 40%;
    left: 30%;
    width: 15px;
    height: 15px;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2.5s ease-out 1s; /* Comienza un poco después */
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  .particle-fire3 {
    position: absolute;
    top: 40%;
    right:  30%;
    width: 15px;
    height: 15px;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2.5s ease-out 1s; /* Comienza un poco después */
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

}
.fire-right {
  height: 100%;
  width: 100%;
  position: absolute;
  animation: shake 2s ease-out 0;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  .main-fire {
    position: absolute;
    top: 15%;
    right: -25%;
    width: 80%;
    height: 80%;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }

  .particle-fire {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 15px;
    height: 15px;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
}

.fire-left {
  position: absolute;
  height: 100%;
  width: 100%;
  animation: shake 3s ease-out 0;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  .main-fire {
    position: absolute;
    top: 15%;
    left: -20%;
    width: 80%;
    height: 80%;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }

  .particle-fire {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 10%;
    height: 10%;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 3s infinite ease-out 0;
    animation-fill-mode: both;
  }
}

.fire-bottom .main-fire {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 75%;
  height: 75%;
  background-color: #ff7800;
  transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 100% 40%;
  filter: blur(10px);
  animation: glow 2s ease-out 0;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loading-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.859); /* Un fondo semitransparente, puedes ajustar según tus necesidades */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ajusta según sea necesario para que esté por encima de otros elementos */
}






@keyframes appear {
  0%, 100% {
    opacity: 0;
    color: transparent;
    text-shadow: none;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    color: #fafafa;
    text-shadow: 0 0 10px #ff9800, 0 0 20px #ff9800, 0 0 30px #ff9800;
    transform: translateY(0);
  }
}
/* .textCargando {
} */

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}



@keyframes appear {
  0%, 100% {
    opacity: 0;
    color: transparent;
    text-shadow: none;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    color: #fafafa;
    text-shadow: 0 0 10px #ff9800, 0 0 20px #ff9800, 0 0 30px #ff9800;
    transform: translateY(0);
  }
}

@keyframes fire {
  0%, 100% {
    text-shadow: 0 0 10px #ff9800, 0 0 20px #ff9800, 0 0 30px #ff9800;
  }
  50% {
    text-shadow: 0 0 15px #ff9800, 0 0 25px #ff9800, 0 0 35px #ff9800;
  }
}

.textCargando span:nth-child(1) { animation-delay: 0.2s; }
.textCargando span:nth-child(2) { animation-delay: 0.6s; }
.textCargando span:nth-child(3) { animation-delay: 1.0s; }
.textCargando span:nth-child(4) { animation-delay: 1.5s; }
.textCargando span:nth-child(5) { animation-delay: 2.0s; }
.textCargando span:nth-child(6) { animation-delay: 2.5s; }
.textCargando span:nth-child(7) { animation-delay: 3.0s; }
.textCargando span:nth-child(8) { animation-delay: 3.5s; }
.textCargando span:nth-child(9) { animation-delay: 4.0s; }
.textCargando span:nth-child(10) { animation-delay: 4.5s; }
.textCargando span:nth-child(11) { animation-delay: 5.0s; }

